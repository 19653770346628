import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Uses`}</h1>
    <p>{`I get `}<a parentName="p" {...{
        "href": "https://wesbos.com/uses/"
      }}>{`no emails about what I use`}</a>{`, but I love bikeshedding about tools. Here’s what I’m currently using.`}</p>
    <h2>{`Editor & Terminal`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com"
        }}>{`Visual Studio Code`}</a>{` is my editor. A younger me would be horrified that I was both using and enjoying a Microsoft product`}</li>
      <li parentName="ul">{`I mostly use Wes Bos’s `}<a parentName="li" {...{
          "href": "https://marketplace.visualstudio.com/items?itemName=wesbos.theme-cobalt2"
        }}>{`Cobalt2`}</a>{` themes everywhere.`}</li>
      <li parentName="ul">{`I use `}<a parentName="li" {...{
          "href": "https://developer.apple.com/fonts/"
        }}>{`SF Mono`}</a>{` for my editor font everywhere.`}</li>
      <li parentName="ul">{`I use `}<a parentName="li" {...{
          "href": "https://iterm2.com"
        }}>{`iTerm2`}</a>{` for my terminal.`}</li>
    </ul>
    <h2>{`Productivity`}</h2>
    <ul>
      <li parentName="ul">{`Apple Reminders and Calendar are how I keep track of what to do and when to do it.`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.calnewport.com/blog/2013/12/21/deep-habits-the-importance-of-planning-every-minute-of-your-work-day/"
            }}>{`Timeblock planning`}</a>{` is how I plan my work days`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://apps.apple.com/us/app/instaremind/id1492317385?mt=12"
            }}>{`InstaRemind`}</a>{` lets me create reminders with a keyboard shortcut in macOS.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://apps.apple.com/us/app/remind-me-faster/id985555908"
            }}>{`Remind Faster`}</a>{` is a great way to quickly add reminders on the go.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://obsidian.md"
        }}>{`Obsidian`}</a>{` is where almost all of my notes go.`}
        <ul parentName="li">
          <li parentName="ul">{`Apple Notes is used for shared notes with my wife (including `}<a parentName="li" {...{
              "href": "https://hachyderm.io/@chrisgervais/109717525272033823"
            }}>{`a squirrel list`}</a>{`).`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.raycast.com"
        }}>{`Raycast`}</a>{` is my quick launcher. I still have `}<a parentName="li" {...{
          "href": "https://www.alfredapp.com"
        }}>{`Alfred`}</a>{` installed for one single reason: Universal Actions. One keyboard shortcut to then move, copy, email, etc files from anywhere in the file system is hard to beat and Raycast has no answer to it yet.`}</li>
    </ul>
    <h2>{`Reading and Highlights`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://raindrop.io"
        }}>{`Raindrop.io`}</a>{` is my bookmarking app of choice. Long ago I used Delicious, then moved to Pinboard, and then to Raindrop. It's nicer looking than Pinboard and has an official client. I got tired of waiting for the third-party Pinboard clients to care again.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://readwise.io"
        }}>{`Readwise`}</a>{` aggregates highlights from a bunch of places (namely Kindle and Instapaper) and sends me a number of highlights to review every day. I can also create flashcards with the goal of retaining what I read.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://readwise.io/read"
        }}>{`Readwise Reader`}</a>{` is where I save articles to read later.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reederapp.com/"
        }}>{`Reeder`}</a>{` is my RSS reader of choice. It beats scrolling through Twitter or Facebook.`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://feedbin.com"
            }}>{`Feedbin`}</a>{` is my RSS service of choice for `}<Link to='/2021-02-17-rss-isnt-dead/' mdxType="Link">{`a number of reasons`}</Link></li>
        </ul>
      </li>
    </ul>
    <h2>{`Travel`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.flightyapp.com"
        }}>{`Flighty`}</a>{` tracks my flights and keeps me aware of delays and cancellations far before the airlines tell you. This has helped me get on the phone to reschedule flights before there's a huge wait.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tripsy.app"
        }}>{`Tripsy`}</a>{` helps me and my wife setup trip plans and save relevant documents and reservations.`}</li>
    </ul>
    <h2>{`Other Apps`}</h2>
    <p>{`This is a mish-mash of web applications and iOS applications as the lines blur.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://1password.com"
        }}>{`1Password`}</a>{` is where I store my 1000+ passwords and it keeps me sane.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apolloapp.io"
        }}>{`Apollo`}</a>{` is my Reddit client. I try not to spend a lot of time on social media, but I like Reddit's focused communities and I can almost always find someone talking about some esoteric thing there. I also sell my used RPG books and board games there.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com"
        }}>{`GitHub`}</a>{` should be obvious to anyone who writes code. I can remember signing up for their paid subscription a long, long time ago because I wanted to do my `}<em parentName="li">{`little`}</em>{` part to keep them around. They're owned by Microsoft now, so I suspect they don't need my help, but they offer so much for so little. Almost all my public repositories with automated testing or linting use GitHub Actions for free.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://helloweather.com"
        }}>{`Hello Weather`}</a>{` is a nice simple way to see the weather that I can share with my wife for one low price.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apps.apple.com/us/app/opener-open-links-in-apps/id989565871"
        }}>{`Opener`}</a>{` lets me open links in various iOS applications without jumping around.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://overcast.fm/"
        }}>{`Overcast`}</a>{` is my podcast app of choice.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.paprikaapp.com"
        }}>{`Paprika`}</a>{` is where I keep our ~1500 recipes, our meal plan for this week, and our grocery list.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://parcel.app"
        }}>{`Parcel`}</a>{` is how I track my packages, though I miss `}<a parentName="li" {...{
          "href": "https://deliveries.app/en.html"
        }}>{`Deliveries`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.personalcapital.com"
        }}>{`Personal Capital`}</a>{` is how I keep up-to-date with all my finances in one place: cash accounts, credit accounts, loans, investments. It's all there.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://workingcopyapp.com"
        }}>{`Working Copy`}</a>{` is for when I need to edit something in a repository when I'm not at a computer.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://app.youneedabudget.com"
        }}>{`YNAB`}</a>{` helps my wife and I keep our budget in alignment with our goals.`}</li>
    </ul>
    <h3>{`Tracking`}</h3>
    <p>{`I started off tracking what I read through Goodreads and enjoy having that record, I've since expanded it to other media:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.bgstatsapp.com"
        }}>{`Board Game Stats`}</a>{` for board game plays`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gametrack.app/user/wesbaker/"
        }}>{`GameTrack`}</a>{` for video games`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.goodreads.com/user/show/3457168-wes-baker"
        }}>{`Goodreads`}</a>{` for books`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://letterboxd.com/wesbaker/"
        }}>{`Letterboxd`}</a>{` for movies`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tvforecastapp.com"
        }}>{`TV Forecast`}</a>{` for TV shows`}</li>
    </ul>
    <h3>{`Content Blockers`}</h3>
    <p>{`The internet is a terrible place full of ads, cookie notifications, and other detritus. I try to remove as much of that as possible.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://oblador.github.io/hush/"
        }}>{`Hush`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://giorgiocalderolla.com/wipr.html"
        }}>{`Wipr`}</a></li>
    </ul>
    <h2>{`Home Automation`}</h2>
    <p>{`I'm firmly in the HomeKit camp at this point.`}</p>
    <ul>
      <li parentName="ul">{`I have two Apple TVs, one LG and one TCL HomeKit-enabled TVs`}</li>
      <li parentName="ul">{`Across the house I have 10 AirPlay 2 speakers: 2 HomePod, 3 HomePod Minis, a Sonos Move, One, Five, Beam, and Arc`}</li>
      <li parentName="ul">{`I use a Starling Hub to put my Nest devices on my HomeKit network`}
        <ul parentName="li">
          <li parentName="ul">{`I have a `}<a parentName="li" {...{
              "href": "https://store.google.com/product/nest_doorbell?hl=en-US&pli=1"
            }}>{`Nest Doorbell`}</a>{` for our doorbell camera`}</li>
          <li parentName="ul">{`I have two `}<a parentName="li" {...{
              "href": "https://store.google.com/us/magazine/compare_cameras"
            }}>{`Nest Cam Indoors`}</a>{` in our house`}</li>
          <li parentName="ul">{`I have one `}<a parentName="li" {...{
              "href": "https://store.google.com/product/nest_cam_battery?hl=en-US"
            }}>{`Nest Cam`}</a>{` outside`}</li>
          <li parentName="ul">{`I have two `}<a parentName="li" {...{
              "href": "https://store.google.com/us/product/nest_learning_thermostat_3rd_gen"
            }}>{`Nest Learning Thermostats`}</a>{` in our house`}</li>
        </ul>
      </li>
      <li parentName="ul">{`I use `}<a parentName="li" {...{
          "href": "https://www.belkin.com/us/p/P-F7C063/"
        }}>{`Wemo Smart Plugs`}</a>{` for holiday decorations and a nightlight in my son's room`}</li>
      <li parentName="ul">{`I'm up to `}<a parentName="li" {...{
          "href": "https://www.philips-hue.com/en-us/products/all-products#filters=BULBS_SU"
        }}>{`6 Philips Hue bulbs and 2 light strips`}</a>
        <ul parentName="li">
          <li parentName="ul">{`I use a `}<a parentName="li" {...{
              "href": "https://www.philips-hue.com/en-us/p/hue-tap-dial-switch/046677578800"
            }}>{`Hue Tap Dial switch`}</a>{` to control my office Hue bulbs`}</li>
          <li parentName="ul">{`I use a `}<a parentName="li" {...{
              "href": "https://www.lutron.com/en-US/products/pages/standalonecontrols/dimmers-switches/smartbulbdimmer/overview.aspx"
            }}>{`Lutron Aurora`}</a>{` to control the lights in the family room`}</li>
          <li parentName="ul">{`I use a `}<a parentName="li" {...{
              "href": "https://www.philips-hue.com/en-us/p/hue-dimmer-switch/046677473372"
            }}>{`dimmer switch remote`}</a>{` to control the bedroom Hue bulbs`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Three of our doors have `}<a parentName="li" {...{
          "href": "https://www.evehome.com/en/eve-door-window"
        }}>{`Eve Door & Window sensors`}</a></li>
      <li parentName="ul">{`Our front door has a `}<a parentName="li" {...{
          "href": "https://level.co/products/bolt"
        }}>{`Level Bolt`}</a>{` so I can see if my front door is locked from my bedroom`}</li>
      <li parentName="ul">{`Our garage door has `}<a parentName="li" {...{
          "href": "https://www.myq.com"
        }}>{`myQ`}</a>{` and I added `}<a parentName="li" {...{
          "href": "https://homebridge.io"
        }}>{`Homebridge running on a Raspberry Pi`}</a>{` so I can tell Siri to close the garage door and setup other automations with it`}</li>
      <li parentName="ul">{`I have some smart switches around the house, namely a `}<a parentName="li" {...{
          "href": "https://www.casetawireless.com/products/dimmers-switches"
        }}>{`Lutron Caseta dimmer`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.casetawireless.com/us/en/products/dimmers-switches"
        }}>{`Lutron Caseta fan switches`}</a>{`, and a `}<a parentName="li" {...{
          "href": "https://www.meross.com/product/23/article/"
        }}>{`Meross Smart Switch`}</a>{`.`}</li>
    </ul>
    <h2>{`The repositories`}</h2>
    <p>{`You can see the rest of what I'm using in my `}<a parentName="p" {...{
        "href": "https://github.com/wesbaker/dotfiles"
      }}>{`dotfiles`}</a>{` repository. It's probably most interesting to look at the `}<a parentName="p" {...{
        "href": "https://github.com/wesbaker/dotfiles/blob/main/Brewfile"
      }}><inlineCode parentName="a">{`Brewfile`}</inlineCode></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      